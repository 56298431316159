<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="10"
        style="max-width: 400px; margin-top: 100px;"
      >
        <v-card>
          <v-img
            class="white--text ma-4"
            contain
            height="200px"
            src="../../assets/logo.jpg"
          />

          <v-card-title primary-title>
            Wachtwoord vergeten
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="email.value"
                  :error-messages="email.errors"
                  label="E-mail"
                  type="email"
                  @blur="validateEmail"
                  @input="email.errors = []"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  :loading="loading"
                  color="primary"
                  depressed
                  @click="submit"
                >
                  <span>Vraag aan</span>
                </v-btn>

                <v-btn
                  :to="{ name: 'signIn' }"
                  depressed
                  small
                  class="ml-2"
                >
                  <span>Terug</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NewSetPasswordRequestPage',
  data() {
    return {
      loading: false,
      email: {
        value: '',
        errors: [],
      },
    };
  },
  beforeMount() {
    if (!this.$route.query.email) {
      return;
    }

    const { email } = this.$route.query;

    if (typeof email === 'string') {
      this.email.value = email.trim().toLowerCase();
      this.validateEmail();
    }
  },
  methods: {
    validateEmail() {
      const newErrors = [];

      if (typeof this.email.value !== 'string') {
        newErrors.push('Is verplicht');
        this.email.errors = newErrors;

        return;
      }

      this.email.value = this.email.value.trim().toLowerCase();
      if (!this.email.value) {
        newErrors.push('Is verplicht');
        this.email.errors = newErrors;

        return;
      }

      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(this.email.value)) {
        newErrors.push('Is geen correct e-mailadres');
      }

      this.email.errors = newErrors;
    },
    submit() {
      this.validateEmail();

      if (this.email.errors.length > 0) {
        return;
      }

      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/set-password-requests`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: {
          email: this.email.value,
        },
        method: 'post',
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Controleer uw e-mail box om verder te gaan.',
            visible: true,
          });

          this.$router.push({ name: 'signIn' });
        })
        .finally(() => {
          this.false = true;
        });
    },
  },
};
</script>
